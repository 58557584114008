import { inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { injectQuery } from '@ngneat/query';
import { Language, PaginationResponseInterface } from '@lobos/common-v3';
import { map } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class LanguageService {
  _http = inject(HttpClient);
  _query = injectQuery();

  getLanguages() {
    return this._query({
      queryKey: ['languages'] as const,
      queryFn: () => {
        return this._http.get<PaginationResponseInterface<Language>>(`/api/languages`).pipe(
          map((res) => {
            return res.data;
          }),
        );
      },
    });
  }
}
